<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.lnb.common_2depth_member_management'),
        url: 'members'
      },
      {
        name: $t('studio.member_manage.history')
      }
    ]"
    :title="$t('studio.member_manage.history')"
  />
  <div class="mt-40">
    <st-box>
      <div class="flex gap-12 items-center">
        <radio-group
          v-model="searchType"
          :options="searchTypeOptions"
          class="flex gap-8"
          @update:modelValue="onSearch"
        >
          <template #default="{ value, label, index, handleModelUpdate }">
            <div :key="index" class="inline-flex">
              <input
                :id="value"
                class="peer absolute top-0 left-0 opacity-0"
                type="radio"
                name="searchType"
                :checked="searchType === value"
                :value="value"
                @change="(e: Event) => handleModelUpdate((e.target as HTMLInputElement)?.value)"
              />
              <label
                class="h-32 flex cursor-pointer items-center rounded-full bg-[#f7fafd] px-12 text-xs font-medium leading-xs text-on-surface-elevation-2 peer-checked:bg-on-surface-elevation-1 peer-checked:text-surface-elevation-1"
                :for="value"
              >
                {{ $t(`${label}`) }}
              </label>
            </div>
          </template>
        </radio-group>
        <div class="w-[22rem] ml-auto">
          <input-text
            name="keyword"
            size="lg"
            variant="outline"
            searchable
            :placeholder="$t('studio.member_manage.history_search_place_holder')"
            :rules="{
              max_length: {
                max: 100
              }
            }"
            :countable="false"
            maxLength="100"
            aggressive
            @search="onSearch"
            @clear="onSearch"
          />
        </div>
      </div>
      <st-table
        :tableClass="'mt-20'"
        :paging="{
          show: (members && members.totalElements > 50) ?? false,
          total: members?.totalElements ?? 0,
          pageSize: DEFAULT_PAGE_SIZE_50
        }"
        :data="members?.contents ?? []"
        :columns="columns"
        @pageChange="onPageChange"
      >
        <template #col-1="{ row: member }">
          <st-td aLeft>
            <span v-if="getHistoryTypeContent(member.historyType)" class="text-md mr-8">{{
              $t(`${getHistoryTypeContent(member.historyType)}`, {
                nickName: member.targetMember.nickname
              })
            }}</span>
            <s-contents-popup
              v-if="
                member.historyType === HistoryType.GROUP_MEMBER_ROLE_MODIFY ||
                  member.historyType === HistoryType.GROUP_MEMBER_MENU_AUTH_MODIFY
              "
              :distance="0"
              :offset="[0, 0]"
              placement="bottom"
              trigger="click"
              :minWidth="460"
              :flipOnUpdate="false"
              class="inline-block"
            >
              <template #target>
                <div class="flex items-center gap-8">
                  <st-tooltip-text-over
                    position="bottom-start"
                    :content="getHistoryDescription(member.modify, member.targetMember.nickname)"
                  >
                    <span class="line-clamp-1 max-w-[32rem]">
                      {{ getHistoryDescription(member.modify, member.targetMember.nickname) }}
                    </span>
                  </st-tooltip-text-over>
                  <button
                    type="button"
                    class="text-xs font-medium underline text-brand-primary align-middle leading-xs inline-block relative top-[-.1rem]"
                  >
                    {{ $t('studio.member_manage.history_status_expand_details') }}
                  </button>
                </div>
              </template>
              <template #contents>
                <s-contents-popup-content-body>
                  <div class="flex flex-col gap-8 max-h-[27.2rem] studio-scrollbar-4">
                    <div
                      v-if="
                        (member.modify.MENU_AUTH_REMOVE &&
                          member.modify.MENU_AUTH_REMOVE.length > 0) ||
                          (member.modify.ROLE_REMOVE && member.modify.ROLE_REMOVE.length > 0)
                      "
                      class="flex flex-col gap-4"
                    >
                      <p class="text-md font-regular leading-lg text-tint-red-a400">
                        {{ $t('studio.member_manage.history_status_edit_depth_details_removed') }}
                      </p>
                      <dl
                        v-for="(info, index) in member.modify.MENU_AUTH_REMOVE"
                        :key="index"
                        class="clear-both text-md font-regular leading-lg text-on-surface-elevation-2"
                      >
                        <dt class="font-medium max-w-[22rem] truncate float-left">
                          [{{
                            info.before?.menuAuthorityId
                              ? $t(getMenuLabelById(info.before?.menuAuthorityId))
                              : ''
                          }}] -&nbsp;
                        </dt>
                        <dd>
                          {{ getDeletePermission(info.before?.permission) }}
                        </dd>
                      </dl>
                      <dl
                        v-for="(info, index) in member.modify.ROLE_REMOVE"
                        :key="index"
                        class="clear-both text-md font-regular leading-lg text-on-surface-elevation-2"
                      >
                        <dt class="font-medium max-w-[22rem] truncate float-left">
                          [{{ info.before?.productName }}] -&nbsp;
                        </dt>
                        <dd>
                          {{
                            $t(
                              'studio.member_manage.history_status_edit_depth_details_removed_msg1',
                              {
                                roleName: getMemberRoleName(info.before?.memberRoleName)
                              }
                            )
                          }}
                        </dd>
                      </dl>
                    </div>

                    <div
                      v-if="
                        (member.modify.MENU_AUTH_ADD && member.modify.MENU_AUTH_ADD.length > 0) ||
                          (member.modify.ROLE_ADD && member.modify.ROLE_ADD.length > 0)
                      "
                      class="flex flex-col gap-4"
                    >
                      <p class="text-md font-regular leading-lg text-brand-primary">
                        {{ $t('studio.member_manage.history_status_edit_depth_details_added') }}
                      </p>
                      <dl
                        v-for="(info, index) in member.modify.MENU_AUTH_ADD"
                        :key="index"
                        class="clear-both text-md font-regular leading-lg text-on-surface-elevation-2"
                      >
                        <dt class="font-medium max-w-[22rem] truncate float-left">
                          [{{
                            info.after?.menuAuthorityId
                              ? $t(getMenuLabelById(info.after?.menuAuthorityId))
                              : ''
                          }}] -&nbsp;
                        </dt>
                        <dd>
                          {{ getAddPermission(info.after?.permission) }}
                        </dd>
                      </dl>
                      <dl
                        v-for="(info, index) in member.modify.ROLE_ADD"
                        :key="index"
                        class="clear-both text-md font-regular leading-lg text-on-surface-elevation-2"
                      >
                        <dt class="font-medium max-w-[22rem] truncate float-left">
                          [{{ info.after?.productName }}] -&nbsp;
                        </dt>
                        <dd>
                          {{
                            $t(
                              'studio.member_manage.history_status_edit_depth_details_added_msg1',
                              {
                                roleName: getMemberRoleName(info.after?.memberRoleName)
                              }
                            )
                          }}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </s-contents-popup-content-body>
              </template>
            </s-contents-popup>
          </st-td>
        </template>
        <template #empty>
          <st-td colspan="4" aCenter class="h-[20rem]"> {{ $t(`${noDataMessage}`) }} </st-td>
        </template>
      </st-table>
    </st-box>
  </div>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { fetchMemberHistoryApi } from '@/apis/member.api';
import StBox from '@/components/common/st-box.vue';
import StTitle from '@/components/common/st-title.vue';
import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { MEMBER_INVITE_HISTORY_STATUS } from '@/constants/my-info.const';
import {
  ROLE_DEVELOPER,
  ROLE_MARKETER,
  ROLE_OPERATOR,
  ROLE_OWNER,
  ROLE_PROJECT_MANAGER
} from '@/constants/permission.const';
import { DEFAULT_PAGE_SIZE_50 } from '@/constants/table.const';
import { Permission } from '@/enums/common.enum';
import { HistoryModifyType, HistoryType } from '@/enums/member.enum';
import type { FormOption } from '@/types/common/form.type';
import type { Pagination } from '@/types/common/pagination.type';
import type { MemberHistoryResponse, ModifyInfo } from '@/types/member/member-response.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { sortValueToDirection } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { getMenuLabelById } from '@/utils/my-info.util';

definePageMeta({
  title: '이력 보기',
  middleware: 'check-menu-writable-middleware'
});

const route = useRoute();
const { t } = useI18n();

const groupId = route.params.groupId as string;

const sortKey = ref<string>();

const { values } = useForm({
  initialValues: {
    keyword: ''
  }
});

const columns = ref<Column[]>([
  {
    title: 'studio.member_manage.history_nick_header1',
    width: '22rem',
    fontWeight: 'normal',
    render: (record: MemberHistoryResponse) =>
      record.historyType === HistoryType.GROUP_MEMBER_KICK ||
      record.historyType === HistoryType.GROUP_MEMBER_REJECT ||
      record.historyType === HistoryType.GROUP_MEMBER_MENU_AUTH_MODIFY ||
      record.historyType === HistoryType.GROUP_MEMBER_ROLE_MODIFY
        ? record.requestMember.nickname
        : record.targetMember.nickname
  },
  {
    title: 'studio.member_manage.history_summary_header2',
    width: 'auto'
  },
  {
    title: 'studio.member_manage.history_status_header3',
    dataIndex: 'historyType',
    align: 'left',
    width: '12rem',
    fontWeight: 'bold',
    render: (record: MemberHistoryResponse) => {
      switch (record.historyType) {
        case HistoryType.GROUP_MEMBER_JOIN:
          return t('studio.member_manage.history_status_joined');
        case HistoryType.GROUP_MEMBER_REJECT:
          return t('studio.member_manage.history_status_declined');
        case HistoryType.GROUP_MEMBER_MENU_AUTH_MODIFY:
          return t('studio.member_manage.history_status_edit_menu');
        case HistoryType.GROUP_MEMBER_ROLE_MODIFY:
          return t('studio.member_manage.history_status_edit_product');
        case HistoryType.GROUP_MEMBER_KICK:
          return t('studio.member_manage.history_status_dismiss');
        case HistoryType.GROUP_MEMBER_LEAVE:
          return t('studio.member_manage.history_status_left');
        default:
          return '';
      }
    }
  },
  {
    title: 'studio.member_manage.history_date_header4',
    dataIndex: 'createdAt',
    align: 'left',
    width: '15.4rem',
    sortKey: 'date',
    fontWeight: 'normal',
    render: (record: MemberHistoryResponse) => getDateTimeByLocale(record.createdAt),
    onSort: ({ sortValue }: ColumnSorting) => {
      const direction = sortValueToDirection(sortValue);

      sortKey.value = direction;
      init({ page: 1, sortKey: sortKey.value, nicknameQuery: values.keyword });
    }
  }
]);

const searchTypeOptions: FormOption[] = [
  {
    value: MEMBER_INVITE_HISTORY_STATUS.ALL,
    label: 'studio.member_manage.history_category_all'
  },
  {
    value: MEMBER_INVITE_HISTORY_STATUS.JOIN,
    label: 'studio.member_manage.history_category_joined'
  },
  {
    value: MEMBER_INVITE_HISTORY_STATUS.REJECT,
    label: 'studio.member_manage.history_category_rejected'
  },
  {
    value: MEMBER_INVITE_HISTORY_STATUS.MODIFY,
    label: 'studio.member_manage.history_category_edit'
  },
  {
    value: MEMBER_INVITE_HISTORY_STATUS.LEAVE,
    label: 'studio.member_manage.history_category_left_gr'
  }
];

const members = ref<Pagination<MemberHistoryResponse[]> | undefined>();

const searchType = ref<string>(MEMBER_INVITE_HISTORY_STATUS.ALL);

const getHistoryTypeContent = (type: HistoryType) => {
  switch (type) {
    case HistoryType.GROUP_MEMBER_JOIN:
      return 'studio.member_manage.history_status_joined_details';
    case HistoryType.GROUP_MEMBER_REJECT:
      return 'studio.member_manage.history_status_declined_details';
    case HistoryType.GROUP_MEMBER_LEAVE:
      return 'studio.member_manage.history_status_left_details';
    case HistoryType.GROUP_MEMBER_KICK:
      return 'studio.member_manage.history_status_dismiss_details';
    default:
      return '';
  }
};

const getHistoryDescription = (
  type: {
    [key in HistoryModifyType]?: ModifyInfo[];
  },
  targetMember: string
) => {
  const isMenu = type.MENU_AUTH_ADD || type.MENU_AUTH_REMOVE;
  if (isMenu) {
    return t('studio.member_manage.history_status_edit_menu_details', { nickName: targetMember });
  }

  return t('studio.member_manage.history_status_edit_product_details', { nickName: targetMember });
};

const onSearch = () => {
  if (values.keyword.length > 100) {
    return;
  }

  init({
    page: 1,
    nicknameQuery: values.keyword,
    sortKey: sortKey.value,
    historyTypes: searchType.value
  });
};

const noDataMessage = computed(() => {
  if (values.keyword) {
    return 'studio.member_manage.search_nmrf';
  }

  return 'studio.member_manage.list_no_data';
});

const onPageChange = async (page: number) => {
  init({
    page,
    nicknameQuery: values.keyword,
    sortKey: sortKey.value,
    historyTypes: searchType.value
  });
};

const getMemberRoleName = (roleName?: string) => {
  if (!roleName) {
    return '';
  }

  switch (roleName) {
    case ROLE_OWNER:
      return t('studio.gnb.group_myinfo.my_group_role_type_owner');
    case ROLE_DEVELOPER:
      return t('studio.member_manage.role_value_developer');
    case ROLE_MARKETER:
      return t('studio.member_manage.role_value_marketer');
    case ROLE_OPERATOR:
      return t('studio.member_manage.role_value_operator');
    case ROLE_PROJECT_MANAGER:
      return t('studio.member_manage.role_value_manager');
    default:
      return roleName;
  }
};

const getAddPermission = (permission: Permission | undefined) => {
  if (!permission) {
    return '';
  }

  if (permission === Permission.READ || permission === Permission.WRITE) {
    return t('studio.member_manage.history_status_edit_depth_details_added_msg2', {
      perName:
        permission === Permission.READ
          ? t('studio.gnb.group_myinfo.my_group.detailed_group_per_view')
          : t('studio.gnb.group_myinfo.my_group.detailed_group_per_edit')
    });
  }

  return t('studio.member_manage.history_status_edit_depth_details_added_msg2_2', {
    perName1: t('studio.gnb.group_myinfo.my_group.detailed_group_per_view'),
    perName2: t('studio.gnb.group_myinfo.my_group.detailed_group_per_edit')
  });
};

const getDeletePermission = (permission: Permission | undefined) => {
  if (!permission) {
    return '';
  }

  if (permission === Permission.READ || permission === Permission.WRITE) {
    return t('studio.member_manage.history_status_edit_depth_details_removed_msg2', {
      perName:
        permission === Permission.READ
          ? t('studio.gnb.group_myinfo.my_group.detailed_group_per_view')
          : t('studio.gnb.group_myinfo.my_group.detailed_group_per_edit')
    });
  }

  return t('studio.member_manage.history_status_edit_depth_details_removed_msg2_2', {
    perName1: t('studio.gnb.group_myinfo.my_group.detailed_group_per_view'),
    perName2: t('studio.gnb.group_myinfo.my_group.detailed_group_per_edit')
  });
};

const init = async (options: {
  page?: number;
  sortKey?: string;
  nicknameQuery?: string;
  historyTypes?: string;
}) => {
  try {
    const historyTypes =
      options.historyTypes === MEMBER_INVITE_HISTORY_STATUS.ALL ? undefined : options.historyTypes;

    const res = await fetchMemberHistoryApi(groupId, {
      page: options.page,
      sortKey: options.sortKey,
      nicknameQuery: options.nicknameQuery,
      historyTypes
    });

    if (res) {
      const contents = res.contents
        .filter((member: MemberHistoryResponse) => {
          if (
            member.historyType === HistoryType.GROUP_MEMBER_ROLE_MODIFY ||
            member.historyType === HistoryType.GROUP_MEMBER_MENU_AUTH_MODIFY
          ) {
            return Object.entries(member.modify).every(
              ([_, value]: [string, ModifyInfo[]]) => value.length > 0
            );
          }

          return member.historyType !== HistoryType.GROUP_MEMBER_NOTIFICATION_MODIFY;
        })
        .map((member: MemberHistoryResponse) => {
          const roleAdd: ModifyInfo[] = [];
          const roleRemove: ModifyInfo[] = [];

          if (member.modify) {
            Object.entries(member.modify).forEach(([_, value]: [string, ModifyInfo[]]) => {
              value.forEach((info: ModifyInfo) => {
                if (!info.before) {
                  roleAdd.push(info);
                }

                if (!info.after) {
                  roleRemove.push(info);
                }
              });
            });
          }

          if (member.historyType === HistoryType.GROUP_MEMBER_MENU_AUTH_MODIFY) {
            member.modify = {
              MENU_AUTH_ADD: roleAdd,
              MENU_AUTH_REMOVE: roleRemove
            };
          } else {
            member.modify = {
              ROLE_ADD: roleAdd,
              ROLE_REMOVE: roleRemove
            };
          }

          return member;
        });

      contents.forEach((content: MemberHistoryResponse) => {
        if (content.modify) {
          // BOTH ADD AND DELETE
          if (
            content.modify.MENU_AUTH_ADD &&
            content.modify.MENU_AUTH_ADD.length > 0 &&
            content.modify.MENU_AUTH_REMOVE &&
            content.modify.MENU_AUTH_REMOVE.length > 0
          ) {
            content.modify.MENU_AUTH_ADD.forEach((addInfo: ModifyInfo) => {
              const remove = (content.modify.MENU_AUTH_REMOVE ?? []).find(
                (info: ModifyInfo) =>
                  addInfo.after?.menuAuthorityId === info.before?.menuAuthorityId
              );

              if (remove) {
                // Add READ_WRITE permission while having READ permission
                if (
                  remove.before?.permission === Permission.READ &&
                  addInfo.after?.permission === Permission.WRITE
                ) {
                  if (content.modify.MENU_AUTH_ADD && addInfo.after) {
                    addInfo.after.permission = Permission.READ_AND_WRITE;
                  }
                }

                // Remove READ_WRITE permission only
                if (
                  remove.before?.permission === Permission.WRITE &&
                  addInfo.after?.permission === Permission.READ
                ) {
                  if (content.modify.MENU_AUTH_REMOVE && remove.before) {
                    remove.before.permission = Permission.READ_AND_WRITE;
                  }
                }
              }

              // Add READ_WRITE and READ permission
              if (!remove && addInfo.after?.permission === Permission.WRITE) {
                addInfo.after.permission = Permission.READ_AND_WRITE;
              }
            });

            // For menus that are only deleted
            const notModifiedMenus = content.modify.MENU_AUTH_REMOVE.filter(
              (removeInfo: ModifyInfo) => {
                const notModifiedPermission =
                  removeInfo.before?.permission !== Permission.READ_AND_WRITE &&
                  removeInfo.after?.permission !== Permission.READ_AND_WRITE;

                return (
                  notModifiedPermission &&
                  !content.modify.MENU_AUTH_ADD?.some((addInfo: ModifyInfo) => {
                    return addInfo.after?.menuAuthorityId === removeInfo.before?.menuAuthorityId;
                  })
                );
              }
            );

            notModifiedMenus.forEach((remove: ModifyInfo) => {
              if (remove.before?.permission === Permission.WRITE) {
                remove.before.permission = Permission.READ_AND_WRITE;
              }
            });
          }

          // ONLY DELETE - Remove both READ and READ_WRITE permission
          if (
            content.modify.MENU_AUTH_REMOVE &&
            content.modify.MENU_AUTH_REMOVE.length > 0 &&
            (!content.modify.MENU_AUTH_ADD || content.modify.MENU_AUTH_ADD.length === 0)
          ) {
            content.modify.MENU_AUTH_REMOVE.forEach((remove: ModifyInfo) => {
              if (remove.before?.permission === Permission.WRITE) {
                remove.before.permission = Permission.READ_AND_WRITE;
              }
            });
          }

          // ONLY ADD - Add both READ and READ_WRITE permission
          if (
            content.modify.MENU_AUTH_ADD &&
            content.modify.MENU_AUTH_ADD.length > 0 &&
            (!content.modify.MENU_AUTH_REMOVE || content.modify.MENU_AUTH_REMOVE.length === 0)
          ) {
            content.modify.MENU_AUTH_ADD.forEach((remove: ModifyInfo) => {
              if (remove.after?.permission === Permission.WRITE) {
                remove.after.permission = Permission.READ_AND_WRITE;
              }
            });
          }
        }
      });

      members.value = {
        ...res,
        contents
      };
    }
  } catch (err) {}
};

init({ page: 1 });
</script>
